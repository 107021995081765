import { createReducer } from 'redux-act';

import {
  STORES_FETCH_DATA_INIT,
  STORES_FETCH_DATA_SUCCESS,
  STORES_FETCH_DATA_FAIL,
  STORES_DELETE_STORE_INIT,
  STORES_DELETE_STORE_SUCCESS,
  STORES_DELETE_STORE_FAIL,
  STORES_CREATE_STORE_INIT,
  STORES_CREATE_STORE_SUCCESS,
  STORES_CREATE_STORE_FAIL,
  STORES_MODIFY_STORE_INIT,
  STORES_MODIFY_STORE_SUCCESS,
  STORES_MODIFY_STORE_FAIL,
  STORES_CLEAN_UP,
  STORES_CLEAR_DATA_LOGOUT,
} from 'state/actions/stores';

const initialState = {
  data: [],
  usersAsStore: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const storesReducer = createReducer(
  {
    [STORES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [STORES_FETCH_DATA_SUCCESS]: (state, { data, usersAsStore }) => ({
      ...state,
      data,
      usersAsStore,
      loading: false,
      error: null,
    }),
    [STORES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [STORES_DELETE_STORE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [STORES_DELETE_STORE_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [STORES_DELETE_STORE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STORES_CREATE_STORE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [STORES_CREATE_STORE_SUCCESS]: (state, { store }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(store),
        loading: false,
        error: null,
        success: true,
      });
    },
    [STORES_CREATE_STORE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STORES_MODIFY_STORE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [STORES_MODIFY_STORE_SUCCESS]: (state, { id, store }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  name: store.name,
                  id,
                  merchantID: store.merchantID,
                  publicKey: store.publicKey,
                  privateKey: store.privateKey,
                  kennitala: store.kennitala,
                  integratorID: store.integratorID,
                  sell: store.sell,
                  orderExport: store.orderExport,
                  orderAPIKey: store.orderAPIKey,
                  productsAPIKey: store.productsAPIKey,
                  categoriesAPIKey: store.categoriesAPIKey,
                  tagsAPIKey: store.tagsAPIKey,
                  logoUrl: store.logoUrl,
                  createdAt: store.createdAt,
                  alwaysInStock: store.alwaysInStock,
                  minStockQty: store.minStockQty,
                  collectionID: store.collectionID,
                  giftcardEmail: store.giftcardEmail,
                  giftcardPassword: store.giftcardPassword,
                  giftcardToken: store.giftcardToken,
                  ordersCompanyID: store.ordersCompanyID,
                  orderExportStatus: store.orderExportStatus,
                  serviceFee: store.serviceFee,
                  paymentFee: store.paymentFee,
                  shippingFee: store.shippingFee,
                  storeFee: store.storeFee,
                  storeFeeMinOrderAmount: store.storeFeeMinOrderAmount,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [STORES_MODIFY_STORE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [STORES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [STORES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
